<template>
    <v-edit-dialog persistent large @save="updateAssignedTo">
        {{ $_.get(assignedTo, "name", "Unassigned") }}
        <v-btn icon small class="ml-1">
            <v-icon small color="primary">mdi-pencil</v-icon>
        </v-btn>
        <template v-slot:input>
            <v-select
                label="Assigned To"
                placeholder="Select Assigned To"
                :value="$_.get(assignedTo, 'id', '')"
                @change="changeAssignedTo"
                :items="screeners"
                item-text="name"
                item-value="id"
                return-object
            ></v-select>
        </template>
    </v-edit-dialog>
</template>
<script>
export default {
    name: "application-list-assigned-to",
    components: {},
    props: {
        assignedTo: {
            required: true,
        },
        screeners: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            assignedToUser: null,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        updateAssignedTo() {
            this.$emit("update:assignedTo", this.assignedToUser);
        },
        changeAssignedTo(user) {
            this.assignedToUser = user;
        },
    },
};
</script>

<style scoped>
</style>