var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-edit-dialog",
    {
      attrs: { persistent: "", large: "" },
      on: { save: _vm.updateAssignedTo },
      scopedSlots: _vm._u([
        {
          key: "input",
          fn: function() {
            return [
              _c("v-select", {
                attrs: {
                  label: "Assigned To",
                  placeholder: "Select Assigned To",
                  value: _vm.$_.get(_vm.assignedTo, "id", ""),
                  items: _vm.screeners,
                  "item-text": "name",
                  "item-value": "id",
                  "return-object": ""
                },
                on: { change: _vm.changeAssignedTo }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(
        " " + _vm._s(_vm.$_.get(_vm.assignedTo, "name", "Unassigned")) + " "
      ),
      _c(
        "v-btn",
        { staticClass: "ml-1", attrs: { icon: "", small: "" } },
        [
          _c("v-icon", { attrs: { small: "", color: "primary" } }, [
            _vm._v("mdi-pencil")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }